import styled from "styled-components";
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'

export const CarouselNav = styled.div`
    width: 100%;
    align-items: center;
    display: flex; 
    justify-content: flex-end;
    margin-top: -8vh;

    @media screen and (max-width: 480px) {
        margin-top: -7.35vh;
    }
`
export const LeftArrow = styled(BsArrowLeft)`
    color: #fff;
    font-size: 1.1rem;
    cursor: pointer;
    margin-top: 0.4vh;

    @media screen and (max-width: 480px) {
        font-size: 1rem;
        margin-right: -2vw;
        margin-top: 0.2vh;
    }
`
export const RightArrow = styled(BsArrowRight)`
    color: #fff;
    font-size: 1.1rem;
    cursor: pointer;
    margin-top: 0.4vh;

    @media screen and (max-width: 480px) {
        font-size: 1rem;
        margin-left: -2vw;
        margin-top: 0.2vh;
    }
`
export const CarouselP = styled.p`
    color: #fff;
    font-size: 2vh;
    margin-left: 3vh;
    margin-right: 3vh;

    @media screen and (max-width: 480px) {
        font-size: 1.8vh;
    }
`