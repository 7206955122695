import styled from 'styled-components'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
    height: 11vh;
    background: transparent;
    display: flex;
    justify-content: flex-start;
    font-size: 1rem;
    position: absolute;
    top: 0;
    z-index: 1200;
    width: 98vw;
    opacity:1;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`
export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 11vh;
    z-index: 1200;
    width: 100vw;
    padding: 0 1.5vw;
`
export const NavLogo = styled.a`
    color: #fff;
    curser: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
    margin-left: 20.4vh;
    
    @media screen and (max-width: 960px) {
        margin-left: 2vw;
        justify-content: flex-start;
    }
    @media screen and (max-width: 480px) {
        font-size: 1.4rem;
        margin-left: 2vh;
    }
`
export const MobileIcon = styled.aside`
    color: #fff;
    top: 19.5px;
    right: 10vh;
    transform: translate(-100%, 60%);
    font-size: 1.7rem;
    position: fixed;
    cursor: pointer;
    align-self: center;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 960px) {
        top: 1.5vh;
        padding-top: 0.4vh;
        font-size: 2.3rem;
    }
    @media screen and (max-width: 480px) {
        top: 1.75vh;
        font-size: 1.6rem;
        right: 0.6vh;
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;
    display: none;
`
export const NavItem = styled.li`
    height: 80px;
`
export const NavLinks = styled(LinkS)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100vh;
    cursor: pointer;

    &.active {
        border-bottom: 3px solid #01bf71;
    }
`