import styled from 'styled-components'

export const Button = styled.a`
    border-radius: 50px;
    white-space: nowrap;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
    }
`