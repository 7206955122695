import React, {useState} from 'react'
import { MainPageContainer, MainPageContent, MainPageH1, MainPageP, MainPageBtnWrapper, KeyboardArrowDown, ArrowDownward, Gif} from './MainPageElements'
import { Button } from '../ButtonElement'

const MainPage = () => {
    const [hover, setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }
  return (
    <MainPageContainer id = {'Main'}>
        <Gif src={require('../../gifs/main-end.gif')} alt='Data is loading...' />
        <MainPageContent>
            <MainPageH1>
            Hello, I am Nauman. <br/>
            </MainPageH1>
            <MainPageP>
            Hardworking and driven software engineer who enjoys positively impacting the lives of others through the use of technology.
            </MainPageP>
            <MainPageBtnWrapper>
                <Button href='#Experiences' onMouseEnter={onHover} onMouseLeave={onHover}>
                    Scroll for More {hover ? <ArrowDownward /> : <KeyboardArrowDown/>}
                </Button>
            </MainPageBtnWrapper>
        </MainPageContent>
    </MainPageContainer>
  )
}

export default MainPage
