import styled from "styled-components";
import {FaTimes} from 'react-icons/fa'

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 10000;
    right: 0;
    top: 0;
    width: 21%;
    height: 100%;
    background: rgb(71, 87, 102);
    display: grid;
    align-items: center;
    top: 0;
    transition: 0.5s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '100%' : '0')};
    top: ${({isOpen}) => (isOpen ? '0' : '-100%')};
    @media screen and (max-width: 960px) {
        width: 100vw;
    } 
`
export const CloseIcon= styled(FaTimes)`
    color: #fff;
`
export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`
export const SidebarWrapper = styled.div`
    color: #fff;

`
export const Sidebarmenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;

    @media screen and (max-width: 960px) {
        grid-template-rows: repeat(6, 10vh);
        align-items: center;
    } 
`
export const SidebarLink = styled.a`
    display: flex;
    align-items: center;
    justify-contents: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2 ease-in-out;
    color: #fff;
    cursor: pointer;

    &:hover {
        color: rgb(1, 156, 191);
        transition: 0.2s ease-in-out;
    }   
`

