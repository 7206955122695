import styled from "styled-components";
import {FaLinkedinIn, FaGithub } from 'react-icons/fa' 
import { HiOutlineEnvelope } from "react-icons/hi2";


export const SideLinkContainer = styled.aside`
    position: fixed;
    z-index: 999;
    right: 7vh;
    width: 9%;
    height: 100vh;
    display: grid;
    grid-template-rows: repeat(3, 15vh);
    align-items: center;
    justify-content: center;
    padding: 30vh 0;
    @media screen and (max-width: 768px) {
        right: 7vw;
    }
`
export const LinkedInIcon = styled(FaLinkedinIn)`
    color: #fff;
    font-size: 1.8rem;

    @media screen and (max-width: 480px) {
        font-size: 1.65rem;
    }
`
export const GithubIcon = styled(FaGithub)`
    color: #fff;
    font-size: 1.8rem;
    @media screen and (max-width: 480px) {
        font-size: 1.65rem;
    }
`
export const EmailIcon = styled(HiOutlineEnvelope)`
    color: #fff;
    font-size: 1.8rem;
    @media screen and (max-width: 480px) {
        font-size: 1.65rem;
    }
`