import React, {useEffect, useState} from 'react'
import {FaBars} from 'react-icons/fa'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks } from './navbarElements'

const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])
  return (
    <>
        <Nav scrollNav={scrollNav}>
            <NavbarContainer>
                <NavLogo href='#Main'>nauman.</NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>
                <NavMenu>
                    <NavItem>
                        <NavLinks to='Main' 
                        spy={true}
                        smooth={true}
                        offset={-80}
                        duration={500}
                        activeClass='active'>
                            Main
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='Experiences'
                        spy={true}
                        smooth={true}
                        offset={-80}
                        duration={500}>
                            Work Experience
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='Projects'
                        spy={true}
                        smooth={true}
                        offset={-80}
                        duration={500}>
                            Projects
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='Contact'
                        spy={true}
                        smooth={true}
                        offset={-80}
                        duration={500}>
                            Contact
                        </NavLinks>
                    </NavItem>
                </NavMenu>
            </NavbarContainer>
        </Nav>
    </>
  )
}

export default Navbar
