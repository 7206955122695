import React from 'react'
import { InfoContainer, InfoWrapper, TextWrapper, Heading, Subtitle, Subheading, Duration, ImageWrapper, Image, DownloadIcon,
Acc, Content, Sect, IconWrapper } from './InfoElements';
import { Gif2 } from '../ContactPage/ContactPageElements';
import Accordian from './Accordian';

const InfoSection = ({id, lightText, headline, description1, description2, description3, description4, description5, description6, description7, description8,
    description9,description10,description11,description12, subheader, subheader2, subheader3, subheader4, length, length2, length3, length4}) => {
    
    const accordianData = [{
        key: 'first',
        title: <Sect>
                    <Subheading lightText={lightText}>
                        {subheader}
                    </Subheading>
                    <Duration lightText={lightText}>
                        {length}
                    </Duration>
                </Sect>,
        content: <div><Subtitle lightText={lightText}>
                    <ul>
                    <li>{description1}</li>
                    <li>{description2}</li>
                    <li>{description3}</li>
                    </ul>
                </Subtitle></div>
        },
        {
        key: 'second',
        title: <Sect>
                    <Subheading lightText={lightText}>
                        {subheader2}
                    </Subheading>
                    <Duration lightText={lightText}>
                        {length2}
                    </Duration>
                </Sect>,
        content: <div><Subtitle lightText={lightText}>
                    <ul>
                    <li>{description4}</li>
                    <li>{description5}</li>
                    <li>{description6}</li>
                    </ul>
                </Subtitle></div>
        },
        {
        key: 'third',
        title: <Sect>
                    <Subheading lightText={lightText}>
                        {subheader3}
                    </Subheading>
                    <Duration lightText={lightText}>
                        {length3}
                    </Duration>
                </Sect>,
        content: <div><Subtitle lightText={lightText}>
                    <ul>
                        <li>{description7}</li>
                        <li>{description8}</li>
                        <li>{description9}</li>
                    </ul>
                </Subtitle></div>
        },
        {
            key: 'fourth',
            title: <Sect>
                    <Subheading lightText={lightText}>
                        {subheader4}
                    </Subheading>
                    <Duration lightText={lightText}>
                        {length4}
                    </Duration>
                    </Sect>,
            content: <div><Subtitle lightText={lightText}>
                        <ul>
                            <li>{description10}</li>
                            <li>{description11}</li>
                            <li>
                                <a href='https://drive.google.com/file/d/1b0ITQ0_gzQPRqpZrqw3YfYjOe7f4fjMH/view' 
                                style={{color: 'white'}} target="_blank" rel="noopener noreferrer">
                                    {description12}
                                </a>
                            </li>
                        </ul>
                    </Subtitle></div>
        }

        
    ]
  return (
    <>
        <InfoContainer id={id}>
        <Gif2 src={require('../../gifs/experience.gif')} alt='Data is loading...' />
            <InfoWrapper>
                <ImageWrapper>
                <Image src= {require('../../images/uob.png')} alt="NCS" />
                    <Image src= {require('../../images/ncs.png')} alt="NCS" />
                    <Image src= {require('../../images/sopra.png')} alt="Sopra" />
                    <Image src= {require('../../images/nus.png')} alt="NUS" />
                </ImageWrapper>
                <TextWrapper>
                    <Heading lightText={lightText}>
                        {headline}
                        <span>  </span> 
                        <IconWrapper>
                        <a href='../../resume/Nauman_resume.pdf' download>
                            <DownloadIcon></DownloadIcon>
                        </a>
                        </IconWrapper>
                    </Heading>
                    <Content>
                        <Subheading lightText={lightText}>
                            {subheader}
                        </Subheading>
                        <Duration lightText={lightText}>
                            {length}
                        </Duration>
                        <Subtitle lightText={lightText}>
                            <ul>
                                <li>{description1}</li>
                                <li>{description2}</li>
                                <li>{description3}</li>
                            </ul>
                        </Subtitle>

                        <Subheading lightText={lightText}>
                            {subheader2}
                        </Subheading>
                        <Duration lightText={lightText}>
                            {length2}
                        </Duration>
                        <Subtitle lightText={lightText}>
                            <ul>
                                <li>{description4}</li>
                                <li>{description5}</li>
                                <li>{description6}</li>
                            </ul>
                        </Subtitle>
                        <Subheading lightText={lightText}>
                            {subheader3}
                        </Subheading>
                        <Duration lightText={lightText}>
                            {length3}
                        </Duration>
                        <Subtitle lightText={lightText}>
                            <ul>
                                <li>{description7}</li>
                                <li>{description8}</li>
                                <li>{description9}</li>
                            </ul>
                        </Subtitle>

                        <Subheading lightText={lightText}>
                            {subheader4}
                        </Subheading>
                        <Duration lightText={lightText}>
                            {length4}
                        </Duration>

                        <Subtitle lightText={lightText}>
                            <ul>
                                <li>{description10}</li>
                                <li>{description11}</li>
                                <li>
                                <a href='https://drive.google.com/file/d/1b0ITQ0_gzQPRqpZrqw3YfYjOe7f4fjMH/view' 
                                style={{color: 'white'}} target="_blank" rel="noopener noreferrer">
                                    {description12}
                                </a>
                            </li>
                            </ul>
                        </Subtitle>
                        </Content>
                    <Acc>
                        {accordianData.map(({ title, content, key }) => (
                        <Accordian title={title} content={content} key={key}/>
                        ))}
                    </Acc>
                </TextWrapper>
            </InfoWrapper>
        </InfoContainer>
    </>
  )
}

export default InfoSection;
