import styled from "styled-components";
import {MdKeyboardArrowDown, MdArrowDownward} from 'react-icons/md'

export const MainPageContainer = styled.div`
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: relative;
    z-index: 1;
`
export const Gif = styled.img`
    background-size: cover;
    height: 100vh;
    width: 100%;
`
export const MainPageContent = styled.div`
    z-index: 3;
    max-width: 100vw;
    position: absolute;
    padding: 8px 1.5vw;
    display: flex;
    flex-direction: column;
`
export const MainPageH1 = styled.h1`
    color: #fff;
    font-size: 5.5vh;
    margin-left: 20.5vh;

    @media screen and (max-width: 768px) {
        font-size: 40px;
        margin-left: 4vw;
        max-width: 73vw; 
    }
    @media screen and (max-width: 480px) {
        font-size: 30px;
        margin-left: 4vw
        max-width: 80vw; 
    }
`
export const MainPageP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 2.7vh;
    max-width: 63vh;
    margin-left: 20.5vh;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        margin-left: 4vw;
        max-width: 70vw; 
    }
    @media screen and (max-width: 480px) {
        font-size: 18px;
        margin-left: 4vw;
        max-width: 73vw; 
        margin-top: 15px;
    }
`
export const MainPageBtnWrapper = styled.div`
    margin-top: 2.5vh;
    display: flex;
    flex-direction: column;
    font-size: 2.5vh;
    margin-left: 20.5vh;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        margin-left: 4vw;
        max-width: 30vw; 
    }
`
export const KeyboardArrowDown = styled(MdKeyboardArrowDown)`
    margin-left: 5px;
    margin-top: 2px;
    font-size: 20px;
`
export const ArrowDownward = styled(MdArrowDownward)`
    margin-left: 5px;
    margin-top: 2px;
    font-size: 20px;
`