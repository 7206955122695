import React from 'react';
import { ProjectPageContainer, ProjectPageContent, ProjectPageH1, ProjectPageH2, PTextWrapper,
    ProjectPageP, ProjectImageWrapper, Image, SectionWrapper, BoldWord } from './ProjectPageElements';
import { Gif2 } from '../ContactPage/ContactPageElements';
import Carousel from './Carousel'

const ProjectPage = () => {
    const handleClick = event => {
        window.open("https://plpgsqldatamanagementapp.herokuapp.com/projectSummary", '_blank').focus();
    }
  return (
    <ProjectPageContainer id = 'Projects'>
        <Gif2 src={require('../../gifs/project.gif')} alt='Data is loading...' />
        <ProjectPageContent>
            <PTextWrapper>
                <ProjectPageH1>
                    Projects
                </ProjectPageH1>
                <Carousel items={[
                    <div>
                        <ProjectPageH2>
                        Organization Management System
                        </ProjectPageH2>
                        <SectionWrapper>
                        <ProjectImageWrapper>
                            <Image onClick = {handleClick} src={require('../../images/database.png')}></Image>
                        </ProjectImageWrapper>
                        <ProjectPageP>
                        Developed a database application that models a company’s sales and private data.
                        Some functionalities include managing sales records and CRUD operations on
                        private data. Designed the ER model for the database and implemented functionalities.
                        <br/><br/>
                        <BoldWord>Tech Stack: </BoldWord>
                        Application uses PostgreSQL RDBMS and front-end uses HTML, CSS,
                        Bootstrap and Django Template Language. Website deployed on a Heroku server.
                        </ProjectPageP>
                    </SectionWrapper>
                    </div>, 
                    <div>
                        <ProjectPageH2>
                        Online Multiplayer Tic-Tac-Toe (Coming Soon)
                        </ProjectPageH2>
                        <SectionWrapper>
                        <ProjectImageWrapper>
                            <Image></Image>
                        </ProjectImageWrapper>
                        <ProjectPageP>
                        An online Tic-Tac-Toe Game to enjoy with your friends, chat and play together online!
                        <br/><br/>
                        <BoldWord>Tech Stack: </BoldWord>
                        The program is written using Java Servlet Technology as well as React. The game itself was built using WebGL.
                        GitHub for version control and collaboration.
                        </ProjectPageP>
                    </SectionWrapper>
                    </div>,
                    <div> 
                        <ProjectPageH2>
                        MEMS Programming
                        </ProjectPageH2>
                        <SectionWrapper>
                        <ProjectImageWrapper>
                            <Image></Image>
                        </ProjectImageWrapper>
                        <ProjectPageP>
                        Using the Sensor Tag running Contiki OS to Implement a basic trace together 
                        token using BLE. Able to discover/pair devices within 15s deterministically 
                        while maintaining low duty cycle. Able to estimate the total duration in which 
                        two nodes are in proximity with high accuracy.
                        <br/><br/>
                        <BoldWord>Tech Stack: </BoldWord>
                        The program is written in C for a microchip with sensors.
                        </ProjectPageP>
                    </SectionWrapper>
                    </div>]} />
            </PTextWrapper>
        </ProjectPageContent>
    </ProjectPageContainer>
  )
}

export default ProjectPage