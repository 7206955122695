import React, { useRef, useEffect}from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, Sidebarmenu, SidebarLink } from './sidebarElements'

const Sidebar = ({isOpen, toggle}) => {
    const myRef = useRef();

    const handleClickOutside = e => {
        if (!myRef.current.contains(e.target) && isOpen) {
            toggle();
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

  return (
    <SidebarContainer ref={myRef} isOpen={isOpen}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <Sidebarmenu>
                <SidebarLink href="#Main" onClick={toggle}>
                    Main
                </SidebarLink>
                <SidebarLink href="#Experiences" onClick={toggle}>
                    Work Experience
                </SidebarLink>
                <SidebarLink href="#Projects" onClick={toggle}>
                    Projects
                </SidebarLink>
                <SidebarLink href="#Contact" onClick={toggle}>
                    Contact
                </SidebarLink>
            </Sidebarmenu>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
