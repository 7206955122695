import React, {useState} from 'react'
import { ContactPageContainer, ContactPageContent, ContactPageH1, ContactPageP, ContactPageBtnWrapper,
    CTextWrapper, Button, Gif2 } from './ContactPageElements'

const ContactPage = () => {
    const [hover, setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }
    const handleClick = () => {
        window.location = 'mailto:e0389102@u.nus.edu'
    }
  return (
    <ContactPageContainer id = 'Contact'>
        <Gif2 src={require('../../gifs/main-end.gif')} alt='Data is loading...' />
        <ContactPageContent>
            <CTextWrapper>
                <ContactPageH1>
                    Get in Touch
                </ContactPageH1>
                <ContactPageP>
                I am currently looking for software engineering opportunities. Feel free to drop me an email!
                </ContactPageP>
                <ContactPageBtnWrapper>
                    <Button onMouseEnter={onHover} onMouseLeave={onHover}
                    primary="true" dark="true" onClick={handleClick}>
                        Say Hi
                    </Button>
                </ContactPageBtnWrapper>
            </CTextWrapper>
        </ContactPageContent>
    </ContactPageContainer>
  )
}

export default ContactPage;