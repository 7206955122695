import React, {useState} from "react";
import { CarouselNav, LeftArrow, RightArrow, CarouselP } from "./CarouselElements";

const Carousel = ({items}) => {
    const [index, setIndex] = useState(0); 
    const length = 3;
   
    const handlePrevious = () => {
        const newIndex = index - 1;
        setIndex(newIndex < 0 ? length - 1 : newIndex);
    };
   
    const handleNext = () => {
        const newIndex = index + 1;
        setIndex(newIndex >= length ? 0 : newIndex);
    };
  return (
    <>
        <CarouselNav>
            <LeftArrow onClick={handlePrevious} />
            <CarouselP>{index + 1} of 3</CarouselP>
            <RightArrow onClick={handleNext}/>
        </CarouselNav>
        <div style={{color:'white'}}>
            {items[index]}
        </div>
    </>
  )
}

export default Carousel;