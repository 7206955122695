export const homeObjOne = {
    id: 'Experiences',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headline: 'Experience',
    subheader: 'Software Engineer at United Overseas Bank',
    length: 'July 2023 - Present',
    description1: 'Create MicroServices to authenticate users and authorize transactions',
    description2: 'Assist in container-based development and deployment using Kubernetes',
    description3: 'Frontend Development of Banking Web Application using React',
    subheader2: 'Systems Developer Intern at NUS-NCS Research Lab',
    length2: 'August 2022 - October 2022',
    description4: 'Develop nodes using ROS2 in C++ and Python',
    description5: 'Use Ignition Gazebo to simulate sensors and test actuator control',
    description6: 'Assist principal investigator in finding vulnerabilities in ROS2 node communication methods',
    subheader3: 'Junior Software Developer at Sopra Steria',
    length3: 'May 2021 - January 2022',
    description7: 'FullStack Development using Spring and Angular Framework',
    description8: 'Design relational schemas and optimize SQL queries (using denormalization and indexes)',
    description9: 'Debugging and Code Testing using Junit',
    subheader4: 'Teaching Assistant at National University of Singapore',
    length4: 'January 2020 - December 2020',
    description10: 'Taught Object-Oriented Programming as well as Data Structures in Java',
    description11: 'Conduct weekly labs and provide feedback to students',
    description12: 'Teaching Review',
};
