import styled from "styled-components";
import { HiDownload } from "react-icons/hi";
import { RiArrowDownSLine, RiArrowRightSLine } from "react-icons/ri"

export const InfoContainer = styled.div`
    background: transparent;
    position: relative;
    z-index: 1;
    height: 100vh;
    width: 100vw;
`
export const InfoWrapper = styled.div`
    display: grid;
    grid-template-columns: .5fr 2.6fr;
    height 100vh;
    width: 100%;
    max-width: 112vh;
    margin-right: auto;
    margin-left: auto;
    padding: 0 1.5vw;
    justify-content: flex-start;
    margin-left: 20.5vh;
    z-index: 1;
    position: relative;

    @media screen and (max-width: 480px) {
        margin-left: 4vw;
        margin-right: 0;
        max-width: 100vw;
        display: flex;
    }
`
export const TextWrapper = styled.div`
    max-width: 100vh;
    padding-top: 0;
    padding-bottom: 5vh;
    margin-top: 8vh;
`
export const Heading = styled.h1`
    color: #fff;
    font-size: 4.8vh;
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 6.7vh;
    margin-left: -17.5vh;

    @media screen and (max-width: 480px) {
        margin-left:0;
        font-size: 28px;
        margin-bottom: 3vh;
    }
`
export const Subheading = styled.h2`
    color: #fff;
    font-size: 2.5vh;
    line-height: 1.5vh;
    font-weight: 500;

    @media screen and (max-width: 480px) {
        font-size: 4.5vw;
        line-height: 5.5vw;
        font-weight: 500;
        max-width: 285px;
    }
`
export const Duration = styled.p`
    font-size: 1.85vh;
    line-height: 1.5vh;
    color: #fff;

    @media screen and (max-width: 480px) {
        font-size: 3.5vw;
        line-height: 1vh;
    }
`
export const Subtitle = styled.div`
    margin-bottom: 4vh;
    font-size: 2vh;
    line-height: 2.5vh;
    color: #fff;
    margin-left: -20px;

    @media screen and (max-width: 480px) {
        font-size: 3.6vw;
        max-width: 340px;
        line-height: 1.9vh;
        margin-bottom: -1vh;
    }
`
export const ImageWrapper = styled.div`
    display: grid;
    grid-template-rows: 18vh 19vh 18vh 19vh;
    align-items: center;
    margin-top: 19vh;

    @media screen and (max-width: 480px) {
        display: none;
    }
`
export const Image = styled.img`
    height: 10vh;
    width: 10vh;

    @media screen and (max-width: 480px) {
        display: none;
    }
`
export const DownloadIcon = styled(HiDownload)`
    color: #fff;
    font-size: 2.6vh;
    margin-top: 0.2vh;

    @media screen and (max-width: 480px) {
        font-size: 2.2vh;
    }
`
export const Content = styled.div`
    @media screen and (max-width: 480px) {
        display: none;
    }
`
export const Acc = styled.div`
    display: none;

    @media screen and (max-width: 480px) {
        display: inline-block;
        margin-top: -1.85vh;
    }
`
export const ArrDown = styled(RiArrowDownSLine)`
    color: #fff;
    font-size: 2.8vh;
`
export const ArrRight = styled(RiArrowRightSLine)`
    color: #fff;
    font-size: 2.8vh;
`
export const Icon = styled.div`
    display: inline-block;
    align-items: center;
`
export const Sect = styled.div`
    display: inline-block;
`
export const IconWrapper = styled.div`
    display: inline-block;
    
    @media screen and (max-width: 480px) {
    display: inline;
    vertical-align: middle; 
    }   
`