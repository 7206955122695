import React from 'react'
import { LinkedInIcon, SideLinkContainer, GithubIcon, EmailIcon } from './sideLinkElements';

const SideLink = () => {
  return (
    <SideLinkContainer>
      <a href='https://sg.linkedin.com/in/nauman-sajid' target="_blank" rel="noopener noreferrer">
        <LinkedInIcon></LinkedInIcon>
      </a>
      <a href='https://github.com/Nauman-S' target="_blank" rel="noopener noreferrer">
        <GithubIcon></GithubIcon>
      </a>
      <a href="mailto:e0389102@u.nus.edu">
        <EmailIcon></EmailIcon>
      </a>
    </SideLinkContainer>
  )
}

export default SideLink;
