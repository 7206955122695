import React, {useState, useEffect, useRef} from 'react'
import ContactPage from '../components/ContactPage'
import InfoSection from '../components/InfoSection'
import { homeObjOne } from '../components/InfoSection/Data'
import MainPage from '../components/MainPage'
import Navbar from '../components/Navbar'
import ProjectPage from '../components/ProjectsPage'
import Sidebar from '../components/Sidebar'
import SideLink from '../components/SideLinks'
import './PageDesign.css'
import { Link } from 'react-router-dom';
import UseViewport from '../components/UseViewport'

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    const fullPageScroll = useRef(null);

    const [currentPage, setCurrentPage] = useState(0);
  
    useEffect(() => {
      fullPageScroll.current.addEventListener('scroll', () => {
        const page = Math.floor((fullPageScroll.current.scrollTop) / (fullPageScroll.current.clientHeight-1));
        setCurrentPage(page);
      });
    }, [currentPage]);
  
    const pages = [<MainPage/>, <InfoSection {...homeObjOne}/>, <ProjectPage/>, <ContactPage/>].map((page, index) => (
      <div key={index} className="page">
        {page}
      </div>
    ));
    const divNames = ['Main', 'Experiences', 'Projects', 'Contact'];

    const handleClick = ({index}) => {
      const div = document.getElementById(divNames[index]);
      div.scrollIntoView();
   }

    const scrollIndicators = ['', '', '', ''].map((indicator, index) => (
      <Link key={index} to={`/#${divNames[index]}`} onClick={() => handleClick({index})}>
      <div className={`indicator ${currentPage === index || (currentPage === 3 && index === 3) 
      ? 'active' : ''}`}>
         {indicator}
      </div>
      </Link>
    ));
    const { width } = UseViewport();
  return (
    <>
    <Sidebar isOpen={isOpen} toggle={toggle}/>
    <Navbar toggle={toggle}/>
    <SideLink />
    <div className="full-page-scroll" ref={fullPageScroll}>
      {pages}
      {width > 960? 
      <div className="scroll-indicator">
        {scrollIndicators}
      </div> : null}
      
    </div>
    </>
  )
}

export default Home;
