import React, {useState} from 'react'
import { ArrDown, ArrRight, Icon } from './InfoElements'

const Accordian = ({title, content}) => {
    const [isActive, setIsActive] = useState(false)

    const updateActive = () => {
        setIsActive(!isActive)
    }
  return (
    <div className='accordian-item'>
        <div className='accordian-title' onClick={updateActive}>
            {title}
            <Icon>{isActive ? <ArrDown /> : <ArrRight />}</Icon>
        </div>
        {isActive && <div className='accordian-content'>{content}</div>}
    </div>
  )
}

export default Accordian
